@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.news-page {
  overflow: hidden;
  font-family: "Inter", sans-serif;
}
.news-heading {
  /* inter/semibold/14 */
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: -0.2px;
}
.scroll {
  max-height: 85vh;
  overflow-y: auto;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 2px;
}
.grey-text {
  color: var(--color-text-text-gray, #575757);
  text-align: left;
  /* inter/regular/12 */
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2px;
}
.modal-title {
  color: #3e3e3e;

  /* inter/bold/24 */
  font-family: "Inter", sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  letter-spacing: -0.5px;
}
