@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Inter", sans-serif;
}

h1,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
}

@media (min-width: 769px) {
  .sidebar-sticky {
    position: fixed;
    top: 0;
    height: 100vh;
    width: 7vw;
    padding-top: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #e0e0e0;
    background-color: #f6f6f6;
    flex-direction: column;
  }
}

.nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.2px;
}
.nav-links {
  display: inline-block;
  background-color: #f6f6f6;
  color: #575757;

  /* inter/semibold/14 */
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
}
.nav-links:hover {
  background-color: #e5e5f2;
  color: #e7953e;
}

.card-title-prices {
  color: #000;

  /* inter/bold/18 */
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: -0.5px;
}

.grey-text {
  color: var(--grey-grey-1000, #6a6a6a);
  /* inter/semibold/12 */
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.2px;
}

.time-text {
  color: var(--grey-grey-1000, #6a6a6a);
  /* inter/semibold/12 */
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  text-align: left;
  line-height: 20px;
  letter-spacing: -0.2px;
}

.light-text {
  /* inter/semibold/14 */
  color: var(--black-50-base, #2e3032);
  font-family: "Inter", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.2px;
}

.big-number {
  /* inter/bold/34 */
  font-family: "Inter", sans-serif;
  font-size: 34px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.5px;
}
