@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
.styles_ticker__-0UvZ 
{
 background-color: transparent !important;
 --gap: -100rem !important;
}
.styles_tickerItem__Vj57z
{
    color: #4A42A0 !important;
}

.styles_tickerItemFinancial__m6Lt7 {
    width: 300px !important;
    padding: 8px 7px !important;
}
.styles_tickerTopLevel__vRrjN
{
    text-transform:capitalize !important;
    font-size:18px !important;
    font-weight:700 !important;
    justify-content: space-evenly !important;
}
.styles_tickerStats__7TXcs {
    display: none !important
}
.styles_tickerItemFinancial__m6Lt7:hover {
    background-color: #E5E5F2 !important;
}
.styles_tickerItem__Vj57z:after
{
    background: red !important;
}