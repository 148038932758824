@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

body {
  overflow-x: hidden;
  overflow-y: auto;
  font-family: "Inter", sans-serif;
}

.big-label {
  color: #393c3c;

  /* inter/semibold/16 */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  letter-spacing: -0.5px;
}

.small-label {
  /* inter/semibold/12 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 166.667% */
  letter-spacing: -0.2px;
}

.radio-text {
  /* inter/medium/14 */
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.2px;
}
