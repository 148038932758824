@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");

* {
  box-sizing: border-box;
}
:root {
  --switches-bg-color: #4c44d8;
  --switches-label-color: white;
  --switch-bg-color: white;
  --switch-text-color: #4c44d8;
}
.switches-container {
  width: 10rem;
  position: relative;
  display: flex;
  padding: 0;
  position: relative;
  background: var(--switches-bg-color);
  line-height: 2rem;
  border-radius: 3rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
}

/* input (radio) for toggling. hidden - use labels for clicking on */
.switches-container input {
  visibility: hidden;
  position: absolute;
  top: 0;
}

/* labels for the input (radio) boxes - something to click on */
.switches-container label {
  width: 50%;
  padding: 0;
  margin: 0;
  text-align: center;
  font-family: "Inter", sans-serif;
  cursor: pointer;
  color: var(--switches-label-color);
}

/* switch highlighters wrapper (sliding left / right) 
    - need wrapper to enable the even margins around the highlight box
  */
.switch-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50%;
  padding: 0.15rem;
  z-index: 3;
  transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);
  /* transition: transform 1s; */
}

/* switch box highlighter */
.switch {
  border-radius: 3rem;
  background: var(--switch-bg-color);
  height: 100%;
}

/* switch box labels
    - default setup
    - toggle afterwards based on radio:checked status 
  */
.switch div {
  width: 100%;
  text-align: center;
  opacity: 0;
  display: block;
  color: var(--switch-text-color);
  transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
  will-change: opacity;
  position: absolute;
  top: 0;
  left: 0;
}

/* slide the switch box from right to left */
.switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
  transform: translateX(0%);
}

/* slide the switch box from left to right */
.switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
  transform: translateX(100%);
}

/* toggle the switch box labels - first checkbox:checked - show first switch div */
.switches-container
  input:nth-of-type(1):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(1) {
  opacity: 1;
}

/* toggle the switch box labels - second checkbox:checked - show second switch div */
.switches-container
  input:nth-of-type(2):checked
  ~ .switch-wrapper
  .switch
  div:nth-of-type(2) {
  opacity: 1;
}
.scrollable-content {
  max-height: 100vh; /* Adjust this value as needed */
  overflow-y: scroll;
  overflow-x: hidden;
}

.scrollable-content::-webkit-scrollbar {
  width: 10px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: grey;
  border-radius: 10px;
}

.scrollable-content::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Optional: add a track color */
}
